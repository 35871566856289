import React from "react";

import { Link } from "gatsby";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { trimSlashes } from "@/utils/stringUtils";

import { LinkBlockItemProps } from "./types";
import "./styles.scss";

const LinkBlockItem = ({ link, urlPrefix }: LinkBlockItemProps) =>
  (
    <div className="link-block-item">
      <Link to={`/${urlPrefix}/${trimSlashes(link.data.attributes.URL)}`} key={link.data.id}>
        <Icon type={IconTypeEnum.Right} size={IconSizeEnum.Size24} />
        <span className="link-block-item__text">
          {link.customDisplayName ?? link.data.attributes.linkDisplayName ?? link.data.attributes.breadcrumbDisplayName}
        </span>
      </Link>
    </div>
  );

export default LinkBlockItem;
