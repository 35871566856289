import { convertToLeadFormDates, convertToLeadFormEmails } from "../constructor/utils";

const emailItem: Queries.Maybe<readonly Queries.Maybe<Queries.Seo_ComponentFormFormEmail>[]> = [{
  id: "",
  formEmailTitle: "E-mail:",
  formEmailDescription: null,
  formEmailRequired: false,
},
];

const birthdateItem: Queries.Maybe<readonly Queries.Maybe<Queries.Seo_ComponentFormFromDate>[]> = [
  {
    id: "75",
    formDateTitle: "Дата рождения:",
    formDateDescription: null,
    formDate18Plus: false,
    formDateExternalId: "birthDate",
    formDateRequired: false,
  },
];

export const emailItems = convertToLeadFormEmails(emailItem, 10);
export const birthdateItems = convertToLeadFormDates(birthdateItem, 20);

