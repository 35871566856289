import React from "react";

import BulletLinkBlockItem from "./BulletLinkBlockItem";
import { BulletLinkBlockProps } from "./types";
import "./styles.scss";

const BulletLinkBlock = ({ links, urlPrefix }: BulletLinkBlockProps) => {
  if (!links.length) {
    return null;
  }

  return (
    <div className="bullet-link-block">
      {links.map((link) =>
        (
          <BulletLinkBlockItem key={link.data.id} link={link} urlPrefix={urlPrefix} />
        ))}
    </div>
  );
};

export default BulletLinkBlock;
