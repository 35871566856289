import React from "react";

import LabelLinkBlockItem from "./LabelLinkBlockItem";
import { LabelLinkBlockProps } from "./types";
import "./styles.scss";

const LabelLinkBlock = ({ links, urlPrefix }: LabelLinkBlockProps) => {
  if (!links.length) {
    return null;
  }
  return (
    <div className="label-link-block">
      {links.map((link) =>
        (
          <LabelLinkBlockItem key={link.data.id} link={link} urlPrefix={urlPrefix} />
        ))}
    </div>
  );
};

export default LabelLinkBlock;
