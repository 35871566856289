import React from "react";

import { Link } from "gatsby";

import { trimSlashes } from "@/utils/stringUtils";

import { LabelLinkBlockItemProps } from "./types";
import "./styles.scss";

const LabelLinkBlockItem = ({ link, urlPrefix }: LabelLinkBlockItemProps) =>
  (
    <div className="label-link-block-item">
      <Link to={`/${urlPrefix}/${trimSlashes(link.data.attributes.URL)}`} key={link.data.id}>
        {link.customDisplayName ?? link.data.attributes.linkDisplayName ?? link.data.attributes.breadcrumbDisplayName}
      </Link>
    </div>
  );

export default LabelLinkBlockItem;
