import { GetCategoryExperts_getExperts_edges } from "./category/graphql/__generated__/GetCategoryExperts";
import { GetConstructorExperts_getExperts_edges } from "./constructor/graphql/__generated__/GetConstructorExperts";
import { GetGidExperts_getExperts_edges } from "./gid/graphql/__generated__/GetGidExperts";
import { GetGoroskopExperts_getExperts_edges } from "./goroskop/graphql/__generated__/GetGoroskopExperts";

export enum LinkUrlPrefixEnum {
  Article = "articles",
  Catalog = "catalog",
  Category = "category",
  Gid = "gidy",
  Goroskop = "goroskop",
  Landing = "landing",
}

export type SeoExpertType = GetCategoryExperts_getExperts_edges &
  GetGidExperts_getExperts_edges &
  GetGoroskopExperts_getExperts_edges &
  GetConstructorExperts_getExperts_edges;

export type CarouselSeoAdminLinkType = {
  customDisplayName: string;
  data: {
    id: number;
    attributes: Queries.Seo_Article;
  };
};

export type SeoAdminLinkType = {
  customDisplayName: string;
  data: {
    id: number;
    attributes: Queries.Seo_Article | Queries.Seo_Catalog | Queries.Seo_Category;
  };
};
