import React from "react";

import LinkBlockItem from "./LinkBlockItem";
import { LinkBlockProps } from "./types";
import "./styles.scss";

const LinkBlock = ({ links }: LinkBlockProps) => {
  if (!links?.length) {
    return null;
  }

  return (
    <div className="link-block">
      <h2>Читайте также</h2>
      {links.map((element) =>
        (
          <LinkBlockItem link={element.link} urlPrefix={element.urlPrefix} />
        ))}
    </div>
  );
};

export default LinkBlock;
