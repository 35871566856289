import React from "react";

import { Link } from "gatsby";

import { trimSlashes } from "@/utils/stringUtils";

import { BulletLinkBlockItemProps } from "./types";

import "./styles.scss";

const BulletLinkBlockItem = ({ link, urlPrefix }: BulletLinkBlockItemProps) =>
  (
    <div className="bullet-link-block-item">
      <Link to={`/${urlPrefix}/${trimSlashes(link.data.attributes.URL)}`} key={link.data.id}>
        {link.customDisplayName ?? link.data.attributes.linkDisplayName ?? link.data.attributes.breadcrumbDisplayName}
      </Link>
    </div>
  );

export default BulletLinkBlockItem;
